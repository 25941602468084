import { useState } from "react";
import { useParams } from "react-router-dom";
import fetchThemeOverride from "../functions/fetchThemeOverride";
import { ThemeOverride } from "../shared/interfaces/ThemeOverride";
import "./styles/reset-password.scss";
import { useQuery } from "../functions/hooks";

const axios = require("axios");

export default function ResetPassword() {
	const { appName } = useParams();
	const query = useQuery();
	const token = query.get("token");

	const [validationError, setValidationError] = useState<null | string>(null);
	const [validationSuccess, setValidationSuccess] = useState<null | string>(null);
	const [loading, setLoading] = useState<number>(0); // 0: Default, 1: Loading, 2: Success

	const validate = (event: any, domain: string) => {
		event.preventDefault();
		const password = event.target[0].value;
		const passwordConfirmation = event.target[1].value;
		setLoading(1);

		if (password !== passwordConfirmation) {
			setValidationError("Password confirmation field doesn't match");
			setLoading(0);
			return;
		}

		if (password.length < 8) {
			setValidationError("Ensure your password contains at least 8 characters");
			setLoading(0);
			return;
		}

		axios
			.put(`${domain}/${token}`, { password })
			.then(() => {
				setLoading(2);
				setValidationSuccess("Successfully updated password");
				setValidationError(null);
			})
			.catch((err: any) => {
				console.log(err.response.data.errorMessage);
				setLoading(0);
				setValidationError(err.response.data.errorMessage);
			});

		return;
	};

	const themeOverride = fetchThemeOverride(appName ?? "") as ThemeOverride;

	let appProps = {} as any;
	if (themeOverride.color) {
		appProps["--secondary-color"] = themeOverride.color;
	}

	/*
	 * If no app is selected, should it show anything at all? We won't be able to request a PW reset due to this app being
	 * frontend only and not being inside the private VPC to make a request
	 */

	return (
		<main style={appProps}>
			<div className="vertically-centered-container">
				<div className="floating-container">
					<div>
						<div>
							{themeOverride.image && (
								<img className="d-ilb mb-0 mt-0 logo-image" src={`/img/${themeOverride.image}`} alt="SEN One service logo" />
							)}
						</div>
						<h1 className="mt-0 mb-0">SEN One</h1>
					</div>

					<form onSubmit={(event: any) => validate(event, themeOverride.domain)}>
						<h3 className="mb-0 maintain-width font-weight-light">Reset your password</h3>

						<div style={{ height: "auto" }} className={`error toast ${validationError !== null ? "toast-animate" : ""}`}>
							{validationError}
						</div>
						<div className={`success toast ${validationSuccess !== null ? "toast-animate" : ""}`}>{validationSuccess}</div>

						{loading === 0 && (
							<div>
								<input type="password" required={true} id="password" placeholder="Enter your new password" />
								<input type="password" required={true} id="password2" placeholder="Confirm your new password" />
								<div>
									<ul style={{ textAlign: "left" }}>
										<span style={{ marginLeft: "-40px" }}>Password must:</span>
										<li>Contain at least one uppercase letter</li>
										<li>Contain at least one number or special character</li>
										<li>Be at least eight characters long</li>
									</ul>
								</div>
								<button name="submitbtn" type="submit">
									Update Password
								</button>
							</div>
						)}

						{loading === 1 && (
							<div>
								<span className="loader"></span>
							</div>
						)}
					</form>
				</div>
				<div className="mt-2 col-primary" style={{ opacity: 0.6 }}>
					Having trouble? Contact us{" "}
					<a style={{ color: themeOverride.color }} href="mailto:apps@sen.com.au" target="_blank" rel="noreferrer">
						here
					</a>
				</div>
			</div>
		</main>
	);
}

export default function fetchThemeOverride(appName: string) {
	let color = "";
	let image = "";
	let domain = "";
	const isLive = process.env.REACT_APP_IS_DEV && process.env.REACT_APP_IS_DEV === "false";

	switch (appName) {
		case "quaddie":
			color = "#D20909";
			image = "quaddie-logo.png";
			domain = isLive ? "https://thequaddie.com.au/api/v1/auth/resetPassword" : "https://dev.sen.com.au/quaddie/api/v1/auth/resetPassword";
			break;
		case "sameracer":
			color = "#47237A";
			image = "same-racer-logo.png";
			domain = isLive ? "https://sameracer.com.au/api/v1/auth/resetPassword" : "https://dev.sen.com.au/sameracer/api/v1/auth/resetPassword";
			break;
		case "survivor":
			color = "#FE502E";
			image = "survivor.png";
			domain = isLive ? "https://api.sensurvivor.com.au/v1/auth/resetPassword" : "https://dev.sen.com.au/survivor/v1/auth/resetPassword";
			break;
		case "insidetrackclub":
			color = "#028755";
			image = "track-logo.png";
			domain = isLive ? "https://api.insidetrackclub.com.au/v1/auth/resetPassword" : "https://dev.sen.com.au/trackclub/v1/auth/resetPassword";
			break;
		case "sen":
			color = "#082132";
			image = "sen.png";
			domain = isLive ? "https://auth.appdata.sen.com.au/v1/auth/resetPassword" : "https://dev.sen.com.au/one/v1/auth/resetPassword";
			break;
	}

	return { color, image, domain };
}

import { useParams } from "react-router-dom";
import fetchThemeOverride from "../functions/fetchThemeOverride";
import { ThemeOverride } from "../shared/interfaces/ThemeOverride";

export default function DeleteAccount() {
	const { appName } = useParams();

	const themeOverride = fetchThemeOverride(appName ?? "") as ThemeOverride;

	let appProps = {} as any;
	if (themeOverride.color) {
		appProps["--secondary-color"] = themeOverride.color;
	}

	return (
		<main style={appProps}>
			<div className="vertically-centered-container">
				<div className="floating-container">
					<div>
						<div>
							{themeOverride.image && (
								<img className="d-ilb mb-0 mt-0 logo-image" src={`/img/${themeOverride.image}`} alt="SEN One service logo" />
							)}
						</div>
						<h1 className="mt-0 mb-0">SEN One</h1>
					</div>
					<h3 style={{ marginBottom: "15px" }} className="mb-0 maintain-width font-weight-light">
						You can request us to permanently delete your SEN One account and all its related data.
					</h3>
					<a
						className="custom-anchor"
						href="mailto:apps@sen.com.au?subject=SEN%20One%20Account%20Deletion&body=I%20would%20like%20to%20permanently%20delete%20my%20SEN%20One%20account%20and%20its%20related%20content%20that%20is%20associated%20with%20this%20email%20address."
						target="_blank"
						rel="noreferrer"
					>
						Request
					</a>
				</div>
				<div className="mt-2 col-primary" style={{ opacity: 0.6 }}>
					Having trouble? Contact us{" "}
					<a style={{ color: themeOverride.color }} href="mailto:apps@sen.com.au" target="_blank" rel="noreferrer">
						here
					</a>
				</div>
			</div>
		</main>
	);
}

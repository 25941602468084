import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ResetPassword from "./components/ResetPassword";
import DeleteAccount from "./components/DeleteAccount";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<div></div>} />
				<Route path="/forgot-password/:appName" element={<ResetPassword />} />
				<Route path="/delete-account/:appName" element={<DeleteAccount />} />
				<Route path="*" element={<div></div>} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
